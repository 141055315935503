<template>
  <div>
    <div>
      <div class="row">
        <div
          v-for="item in loadedDeliveryServices"
          :key="item.id"
          class="col-6 col-md-3 q-pa-md"
          :class="getIsVisible(item)"
        >
          <div class="source-card full-height">
            <div
              class="source-card__head text-center q-pa-xs"
              style="height: 150px;"
            >
              <img
                v-if="item.logo"
                style="height: 100%; width: 100%; object-fit: contain;"
                :src="getLogoUrl(item.logo)"
                :alt="item.name"
              >

              <div
                v-else
                class="row justify-center items-center text-center full-height text-subtitle1"
              >
                {{ item.name }}
              </div>
            </div>

            <div class="source-card__body">
              <div class="full-width">
                <h5 class="q-my-none">
                  {{ item.name }}
                </h5>

                <q-btn
                  color="light-blue-9"
                  text-color="white"
                  size="sm"
                  :label="$t('Setup')"
                  no-caps
                  unelevated
                  @click="handleSelect(item)"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!isDisabledObs"
          v-observe-visibility="loadNextDeliveryServices"
        />

        <div
          v-if="deliveryServicesLoading"
          class="col-12 row fit justify-center items-center"
          style="min-height: 260px;"
        >
          <q-spinner
            color="light-blue-9"
            size="3rem"
          />
        </div>
      </div>
    </div>

    <d-s-integration-modal
      ref="dsIntegrationModal"
      @submit="handleSubmit"
      @close="handleClose"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Directives
import { ObserveVisibility } from 'vue-observe-visibility'

// Components
import Search from '../search/Search.vue'
import DSIntegrationModal from './DSIntegrationModal.vue'
import FilterCollapse from '../filters/FilterCollapse.vue'

// Helpers
import { getLogoUrl } from "./../../helpers/helpers";

export default {
  name: 'DSIntegration',
  emits: ['submit'],
  components: {
    Search,
    DSIntegrationModal,
    FilterCollapse
  },
  directives: {
    ObserveVisibility
  },
  props: {
    data: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isOpen: false,
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'state',
        'id',
        'name'
      ],
      filter: [{ type: 'in', field: 'state', values: ['active', 'inactive'] }],
      loadedDeliveryServices: [],
      deliveryService: null,
      search: '',
      isDisabledObs: true,
      sid: null
    }
  },
  computed: {
    ...mapGetters([
      'deliveryServices',
      'deliveryServicesLoading',
      'deliveryServicesPage',
      'deliveryServicesTotalPages',
      'integration',
      'appOptions'
    ])
  },
  watch: {
    deliveryServices () {
      this.handleChange('deliveryServices')
    }
  },
  mounted () {
    this.sid = this.$store.state.selectedCourier;

    this.handleChange('deliveryServices')

    if (this.integration) {
      this.deliveryService = this.integration._embedded.deliveryService
      this.$refs.dsIntegrationModal.open()
    } else if (!this.integration && this.$route.params.id) {
      this.loadIntegration(this.$route.params.id)
        .then(() => {
          this.deliveryService = this.integration._embedded.deliveryService
          this.$refs.dsIntegrationModal.open()
        })
    }

    this.loadNextItems(this.search, 1, 'deliveryServices')
      .then(() => {
        this.isDisabledObs = this.deliveryServicesPage >= this.deliveryServicesTotalPages
      })
  },
  unmounted () {
    this.setIntegration(null)
  },
  methods: {
    getLogoUrl,
    ...mapMutations([
      'addErrorNotification',
      'addWarningNotification',
      'setIntegration',
      'setNewIntegration',
      'updateIntegration'
    ]),
    ...mapActions([
      'loadDeliveryServices',
      'loadIntegration'
    ]),
    handleFiltersSubmit (filter) {
      this.filter = filter

      if (!this.filter.find(x => x.field === 'state')) {
        this.filter.push({ type: 'in', field: 'state', values: ['active', 'inactive'] })
      }

      this.isOpen = false

      return this.loadNextItems(this.search, 1, 'deliveryServices')
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    handleSubmit (item) {
      this.$emit('submit', item)

      if (this.$route.path.includes('integration')) {
        this.$router.push('/settings/integrations/shipping')
      }
    },
    handleClose () {
      if (this.$route.path.includes('integration')) {
        this.$router.push('/settings/integrations/shipping')
      }
    },
    loadNextDeliveryServices () {
      this.isDisabledObs = true

      return this.loadNextItems(this.search, this.deliveryServicesPage + 1, 'deliveryServices')
        .then(() => {
          this.isDisabledObs = this.deliveryServicesPage >= this.deliveryServicesTotalPages
        })
    },
    handleSelect (deliveryService) {
      this.deliveryService = deliveryService

      this.setNewIntegration()
      this.updateIntegration({
        _embedded: {
          ...this.integration._embedded,
          deliveryService
        }
      })

      this.$refs.dsIntegrationModal.open()
    },
    getIsVisible (item) {
      return (this.sid && item.id !== this.sid)
        ? 'd-none'
        : ''
    },
    handleSearchSubmit (search) {
      this.search = search
      this.loadedDeliveryServices = []

      this.isDisabledObs = true

      return this.loadNextItems(search, 1, 'deliveryServices')
        .then(() => {
          this.isDisabledObs = this.deliveryServicesPage >= this.deliveryServicesTotalPages
        })
    },
    createQuery (params, loader) {
      const query = {
        per_page: 25,
        filter: [
          { type: 'in', field: 'state', values: ['active', 'inactive'] }
        ],
        ...params
      }

      if (loader === 'deliveryServices') {
        query.filter = this.filter
        if (this.appOptions.country?.id) {
          this.filter.push({ type: 'eq', field: 'country', value: this.appOptions.country.id })
        }
      }

      return query
    },
    loadNextItems (search, page, loader) {
      const loaders = {
        deliveryServices: this.loadDeliveryServices
      }

      if (typeof loaders[loader] !== 'function') {
        this.addErrorNotification('Items loader is unknown!')
        return
      }

      const query = this.createQuery({ search, page }, loader)

      return loaders[loader](query)
    },
    handleChange (type) {
      const types = {
        deliveryServices: {
          getter: 'deliveryServices',
          page: 'deliveryServicesPage',
          loadedValue: 'loadedDeliveryServices'
        }
      }

      const current = types[type]

      if (!current) {
        this.addErrorNotification('Unable to handle filter change!')
        return
      }

      if (this[current.page] <= 1) {
        this[current.loadedValue] = this[current.getter]
      } else {
        this[current.loadedValue] = [
          ...this[current.loadedValue],
          ...this[current.getter]
        ]
      }
    }
  }
}
</script>
